<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <ValidationObserver
        ref="form"
      >
        <anomalia-form
          v-if="pregunta && pregunta.pregunta_checklist_ot"
          :id="routeParams.idtanomalia_checklist_ot"
          :idpregunta-checklist-ot="pregunta.pregunta_checklist_ot.idpregunta_checklist_ot"
          :has-agregar-a-definicion-checklist="hasAgregarADefinicionChecklist"
          :has-anomalia-texto-libre="hasAnomaliaTextoLibre"
          @change-values="changeFormData"
        />
      </ValidationObserver>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import AnomaliaForm from '../components/AnomaliaForm'
import Data from './AnomaliaEditData'
import { get } from 'vuex-pathify'

export default {
  components: {
    AnomaliaForm
  },
  mixins: [formPageMixin],
  data () {
    return {
      pregunta: {},
      hasAgregarADefinicionChecklist: true,
      hasAnomaliaTextoLibre: true,
    }
  },
  computed: {
    usuarioIdtecnico: get('usuario/idtecnico'),
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Modificar anomalía'
      this.pregunta = await this.$offline.preguntaChecklistOt.row(this.routeParams.idpregunta_checklist_ot)
      this.hasAgregarADefinicionChecklist = this.hasViewPerm(this.permissions.anomaliaAgregarADefinicionChecklist.id)
      this.hasAnomaliaTextoLibre = this.hasPerm(this.permissions.anomaliaTextoLibre.id)
    },
    changeFormData (formData) {
      this.formData = formData
    },
    async submitForm () {
      await Data.update(
        this,
        this.routeParams.idtanomalia_checklist_ot,
        this.formData,
        this.pregunta.pregunta_checklist_ot.idpregunta_checklist_ot,
        this.usuarioIdtecnico
      )
      await this.$dirty.modified(this.$dirty.ENTITIES.local.preguntaChecklistOt, this.pregunta.pregunta_checklist_ot.idpregunta_checklist_ot)
      await this.$dirty.modified(this.$dirty.ENTITIES.local.tanomaliaChecklistOt, this.routeParams.idtanomalia_checklist_ot)
      this.$appRouter.go(-1)
    },
  },
}
</script>
