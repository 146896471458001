export default {
  async update (Vue, id, formData, idpreguntaChecklistOt, idtecnico) {
    await Vue.$offline.tanomaliaChecklistOt.updateSync({
      idtanomalia_checklist_ot: id,
      idtanomalia_checklist: formData.idtanomalia_checklist,
      idgrado_anomalia: formData.idgrado_anomalia,
      descripcion: formData.descripcion,
      descripcion_ampliada: formData.descripcion_ampliada,
      mejora: formData.mejora,
      subsanado: formData.subsanado,
      agregaradefinicioncheck: formData.agregaradefinicioncheck,
      notas_internas: formData.notas_internas,
    })
    // actualizar el grado de anomalía de la pregunta
    // al máximo orden de los grados de anomalía de sus anomalías
    await Vue.$offline.preguntaChecklistOt.updateMaxGradoSync(idpreguntaChecklistOt, idtecnico)
  },
}
